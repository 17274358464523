import { baseUrl } from './env';
import axios from 'axios';
import store from '@/store';
import Message from '@/components/SGY/message/message';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken, removeAccessToken, removeRefreshToken } from "@/store/localStorage";

/** 创建axios实例 */
const service = axios.create( { baseURL: baseUrl + '/api', timeout: 60000 } );
/** 请求拦截器 */
service.interceptors.request.use(
  config => {
    const token = getAccessToken();
    if ( token ) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }
    return config;
  },
  error => {
    return Promise.reject( error );
  }
);

/** 响应拦截器 */
service.interceptors.response.use( ( response ) => {
  if ( response.status === 200 ) return response.data;
  return response;
}, async ( error ) => {
  if ( error.response.status === 401 ) {
    Message( { type: 'error', message: '还未登录，请登录！' } );
    return Promise.reject( error );
  }
  if ( error.response.status === 403 ) {
    try {
      const token = await service.post( '/refreshtoken', { refreshToken: getRefreshToken() } );
      setAccessToken( token.newAccessToken );
      setRefreshToken( token.newRefreshToken );
      // 重新发送原始请求
      return service( error.response.config );
    } catch ( err ) {
      removeAccessToken();
      removeRefreshToken();
      store.state.isMobile ? window.location.href = '/#/loginH5' : window.location.href = '/#/loginPc';
      return Promise.reject( err );
    }
  }
  return Promise.reject( error );
} );

export default service;