<template>
  <div class="room-container" @click="onToChatroom">
    <div class="room-top">
      <div class="room-name"><i class="iconfont icon-fangjian"></i>
        <div class="text">{{ chatroom.title }}</div>
      </div>
      <div class="room-date">{{ formatDate(chatroom.createTime) }}</div>
      <div class="person-number">
        <i class="iconfont icon-zongrenshu"></i>
        <div>{{ chatroom.memberCount }}</div>
      </div>
    </div>
    <div class="room-describe">{{ chatroom.message }}</div>
    <div class="room-bottom">
      <div class="room-status" :class="chatroom.status ? 'use' : 'stop'">{{ chatroom.status ? '正在使用' : '暂时关闭' }}</div>
      <div class="room-date copyable" v-if="chatroom.roomKey" @click.stop>密钥&nbsp;{{ chatroom.roomKey }} <i
          class="iconfont icon-fuzhi" @click.stop="onCopyRoomKey(chatroom.roomKey)"></i></div>
      <i class="iconfont" :class="true ? 'icon-tabtubiao-' : 'icon-tabtubiao-1'"></i>
    </div>

    <div title="有新消息" class="have-notify-sub" v-if="chatroom.hasNotify"></div>

    <div class="back-image" :style="{ 'background': props.roomColor }"></div>
  </div>
</template>

<script>
export default { name: 'ExclusiveRoom' };
</script>
<script setup>
import ShowDialog from '@/components/SGY/ShowDialog/ShowDialog';
import Message from '@/components/SGY/message/message';
import { verifyRoomKeyApi, insertRoomMembersApi, findRoomMembersApi } from '@/api/index';
import { formatDate, onCopyRoomKey } from '@/utils/handleFn';
import { defineEmits, defineProps, computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const emit = defineEmits( ['toChatroom'] );
const props = defineProps( { chatroom: Object, roomColor: String } );
const roomMemberParams = ref( {
  userId: store.state.user?.id || '',
  roomId: '',
  name: store.state.user?.nickname || '',
  avatar: store.state.user?.avatar || '',
  createTime: new Date(),
  status: 0,
} );

const chatroom = computed( () => props.chatroom );

// 跳转到聊天室
const onToChatroom = () => {
  // 房间管理员直接进入
  if ( Number( store.state.user.id ) === Number( chatroom.value.userId ) ) return emit( 'toChatroom', chatroom.value.roomNumber );
  if ( Number( chatroom.value.status ) === 1 ) {
    // 判断该用户是否已加入房间
    findRoomMembersFn();
  } else {
    Message( { message: '该房间已关闭', type: 'warning' } );
  }
};

const handleConfirm = ( roomKey ) => {
  if ( roomKey && roomKey.trim() !== '' ) {
    roomKeyVerifyFn( roomKey );
  } else {
    Message( { message: '请输入房间密钥', type: 'warning' } );
  }
};

// 获取房间成员
const findRoomMembersFn = async () => {
  try {
    let res = await findRoomMembersApi( { roomId: chatroom.value?.id, userId: store.state.user?.id } );
    if ( res.code === 200 && res.message[0].count === 1 ) {
      emit( 'toChatroom', chatroom.value.roomNumber );
    } else {
      ShowDialog( { title: '进入房间', input: '请输入该房间密钥', cancel: true, onConfirm: handleConfirm } );
    }
  } catch ( error ) {
    console.log( error );
  }
};

// 验证房间密钥
const roomKeyVerifyFn = async ( roomKey ) => {
  if ( !chatroom.value || !chatroom.value.roomNumber ) return;
  try {
    let res = await verifyRoomKeyApi( { roomKey: roomKey, roomNumber: chatroom.value.roomNumber } );
    if ( Number( res.code ) === 200 ) {
      insertRoomMembersFn();
      emit( 'toChatroom', chatroom.value.roomNumber );
    } else {
      Message( { message: res.message, type: 'warning' } );
    }
  } catch ( err ) {
    console.log( err );
    Message( { message: '系统开车了，请稍后再试', type: 'error' } );
  }
};

// 房间新增用户
const insertRoomMembersFn = async () => {
  roomMemberParams.value.roomId = chatroom.value?.id;
  try {
    let res = await insertRoomMembersApi( roomMemberParams.value );
    if ( Number( res.code ) === 200 ) {
      Message( { message: res.message, type: 'success' } );
    }
  } catch ( err ) {
    console.log( err );
  }
};
</script>
<style lang="less" scoped>
.room-container {
  position: relative;
  width: 100%;
  min-height: 100px;
  padding: 5px 10px 10px;
  border-radius: 10px;
  background-color: @gray-10-4;
  border: 1px solid @gray-8;
  backdrop-filter: blur(10px);

  .back-image {
    content: '';
    position: absolute;
    top: 6%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: @linear-2-w;
    filter: blur(30px);
  }

  .decorate {
    position: absolute;
    top: 0;
    right: 0;
    filter: blur(3px);
    z-index: -1;
  }

  .room-top {
    .flex-s;
    font-size: 14px;

    .room-name {
      .flex-s;
      max-width: 40%;

      .icon-fangjian {
        font-size: 26px;
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .room-date {
      font-size: 12px;
      margin: 0 20px;
    }

    .person-number {
      margin-left: auto;
      .flex;

      .icon-zongrenshu {
        font-size: 18px;
      }
    }
  }

  .room-describe {
    text-align: left;
    padding-right: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;

    /* 兼容性修复 */
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1em;
    }
  }

  .room-bottom {
    width: 100%;
    margin-top: 8px;
    position: relative;
    .flex-s;
    gap: 20px;

    .room-status {
      width: 64px;
      text-align: center;
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 2px;
      color: @gray-10;
    }

    .room-date {
      font-size: 12px;
      .flex;
      gap: 5px;
    }

    .copyable {
      .copyable;
    }

    [class*="icon-tabtubiao"] {
      position: absolute;
      bottom: -6px;
      right: 10px;
      font-size: 32px;
    }
  }
}
</style>