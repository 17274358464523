import { getTokenPayload } from '../utils/handleFn';
import store from '../store';
import eventBus from '@/utils/eventBus';

/** 获取短token */
export const getAccessToken = () => {
  return localStorage.getItem( 'accessToken' );
};

/** 获取长token */
export const getRefreshToken = () => {
  return localStorage.getItem( 'refreshToken' );
};

/** 设置短token */
export const setAccessToken = ( value ) => {
  const user = getTokenPayload( value );
  store.state.user = 'result' in user ? user.result : user;
  localStorage.setItem( 'accessToken', value );
};

/** 设置长token */
export const setRefreshToken = ( value ) => {
  localStorage.setItem( 'refreshToken', value );
};

/** 清除短token */
export const removeAccessToken = () => {
  store.state.user = null;
  localStorage.removeItem( 'accessToken' );
};

/** 清除长token */
export const removeRefreshToken = () => {
  localStorage.removeItem( 'refreshToken' );
};

/** 
 * 获取网站配置
 * type: 0获取全部 1获取主体背景 2获取胶囊背景 3获取房间背景 4获取按钮配色 5获取展示数据 6获取更新时间
 */
export const getWebConfig = ( type = 0 ) => {
  return new Promise( resolve => {
    let webConfig = null;
    try {
      webConfig = localStorage.getItem( 'webConfig' );
    } catch ( error ) { console.log( error ); }
    if ( !webConfig ) {
      webConfig = JSON.stringify( {
        mainColor: 'radial-gradient(circle,#90CF5B 15%, transparent 0%)',
        capsuleColor: 'linear-gradient(135deg, rgba(255, 160, 0, 0.15), transparent 100%)',
        roomColor: 'linear-gradient(90deg, rgba(30, 83, 255, 0.15), transparent 100%)',
        buttonColor: '#3B73F0',
        showBounty: 1,
        updateTime: 0
      } );
    }
    if ( type === 0 || !type ) {
      resolve( JSON.parse( webConfig ) );
    } else if ( type === 1 ) {
      resolve( JSON.parse( webConfig ).mainColor );
    } else if ( type === 2 ) {
      resolve( JSON.parse( webConfig ).capsuleColor );
    } else if ( type === 3 ) {
      resolve( JSON.parse( webConfig ).roomColor );
    } else if ( type === 4 ) {
      resolve( JSON.parse( webConfig ).buttonColor );
    } else if ( type === 5 ) {
      resolve( JSON.parse( webConfig ).showBounty );
    } else if ( type === 6 ) {
      resolve( JSON.parse( webConfig ).updateTime );
    }
  } );
};

/** 设置网站配置 */
export const setWebConfig = ( value ) => {
  if ( typeof value != 'string' ) {
    value = JSON.stringify( value );
  }
  localStorage.setItem( 'webConfig', value );
  eventBus.$emit( 'webConfig', JSON.parse( value ) );
};