<template>
  <div class="form-container">
    <div class="form-box" :style="{ 'top': store.state.safeArea.top + 'px', 'marginTop': props.flag && 0 }" @click.stop>
      <div class="form-describe">
        <div class="form" :class="isReadOnly && 'noclick'">
          <div class="key">主题</div>
          <div class="value">
            <input class="input pink" type="text" :disabled="isReadOnly" v-model="paramsData.title">
          </div>
        </div>
        <div class="form" :class="(isCover || isReadOnly) && 'noclick'">
          <div class="key">写信人</div>
          <div class="value">
            <input class="input" type="text" :disabled="isCover || isReadOnly" v-model="paramsData.name">
          </div>
        </div>
        <div class="form" :class="(isCover || isReadOnly) && 'noclick'">
          <div class="key">投递邮箱</div>
          <div class="value">
            <input class="input" type="text" :disabled="isCover || isReadOnly" v-model="paramsData.email">
          </div>
        </div>
        <div class="form" :class="isReadOnly && 'noclick'">
          <div class="key">未来时间</div>
          <div class="value">
            <input class="input" type="date" :disabled="isReadOnly" v-model="paramsData.sendTime">
          </div>
        </div>
        <div class="form" :class="(isCover || isReadOnly) && 'noclick'">
          <div class="key">邮件类型</div>
          <div class="value radio-box">
            <div class="radio" :class="paramsData.type == 0 && 'radioActive'" @click="paramsData.type = 0">保密</div>
            <div class="radio" :class="paramsData.type == 1 && 'radioActive'" @click="paramsData.type = 1">公开</div>
          </div>
        </div>
      </div>
      <div class="form-content">
        <quill-editor content-type='html' :class="isReadOnly && 'disabled'" v-model:content='paramsData.content'
          :readOnly="isReadOnly" :options='options'>
        </quill-editor>
      </div>
      <div class="form-submit">
        <SGYButton medium line @click.stop="onCloseModal">关闭</SGYButton>
        <SGYButton v-if="!isCover && !isReadOnly" medium line @click="onSubmit">提交</SGYButton>
        <SGYButton v-if="isCover && !isReadOnly" medium line @click="onCover">覆盖</SGYButton>
        <SGYButton v-if="isCover && !isDelete" medium line @click="isDelete = true">删除</SGYButton>
        <SGYButton v-if="isCover && isDelete" medium line @click="onDelete">确认</SGYButton>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'TimeCapsuleForm' };
</script>
<script setup>
import SGYButton from './SGYButton.vue';
import Message from '@/components/SGY/message/message';
import { insertCapsulesApi, updateCapsulesApi, deleteCapsulesApi } from '@/api/index';
import { formatDate, base64ToString } from '@/utils/handleFn';
import { ref, defineEmits, defineProps, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const emit = defineEmits( ['close', 'load'] );
const props = defineProps( { capsule: Object, type: Number, flag: { default: false, type: Boolean } } );
const capsule = ref( props.capsule && JSON.parse( JSON.stringify( props.capsule ) ) || {} );
const options = {// 富文本配置
  placeholder: '给ta写点什么...',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      [{ indent: '+1' }, { indent: '-1' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['clean']
    ],
  },
};
const isReadOnly = ref( false ); // 是否只读
const isCover = ref( false ); // 是否为覆盖
const isDelete = ref( false ); // 确认删除
const dayLater = formatDate( new Date().setDate( new Date().getDate() + 1 ) ); // 一周后
const paramsData = ref( {
  userId: store.state.user && store.state.user.id,     // 用户id
  name: store.state.user && store.state.user.nickname, // 昵称
  email: '',      // 邮箱
  title: '',      // 主题
  content: '<p></p>',    // 内容
  status: 0,      // 状态(0未寄出，1已寄出)
  type: props.type || 0,       // 类型(0保密，1公开)
  createTime: new Date(), // 创建时间
  sendTime: dayLater,   // 发送时间
} );

if ( 'userId' in capsule.value ) {
  paramsData.value = capsule.value;
  paramsData.value.content = props.flag ? paramsData.value.content : base64ToString( paramsData.value.content );
  isCover.value = Number( paramsData.value.userId ) === Number( store.state.user && store.state.user.id );
  isReadOnly.value = Number( paramsData.value.userId ) !== Number( store.state.user && store.state.user.id ) || paramsData.value.status == 1;
}

const dataCheck = ( type ) => {
  if ( !paramsData.value.title || paramsData.value.title.trim() == '' ) {
    console.log( paramsData.value.title );
    Message( { message: '请输入主题', type: 'error' } );
    return false;
  }
  if ( type !== 'cover' ) {
    if ( !paramsData.value.name || paramsData.value.name.trim() == '' ) {
      Message( { message: '请输入写信人', type: 'error' } );
      return false;
    }
    const reg = new RegExp( "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$" ); //正则表达式
    if ( !paramsData.value.email || !reg.test( paramsData.value.email.trim() ) ) {
      Message( { type: 'error', message: '请输入正确的邮箱地址' } );
      return false;
    }
  }
  if ( paramsData.value.sendTime < dayLater ) {
    Message( { message: '未来时间不能小于一天', type: 'error' } );
    return false;
  }
  if ( paramsData.value.content == '<p></p>' || paramsData.value.content == '<p><br></p>' ) {
    Message( { message: '请输入内容', type: 'error' } );
    return false;
  }
  return true;
};

const onSubmit = async () => {
  if ( dataCheck() ) {
    try {
      const res = await insertCapsulesApi( paramsData.value );
      if ( Number( res.code ) === 200 ) {
        Message( { message: res.message, type: 'success' } );
        onLoad( 1 );
      }
    } catch ( err ) {
      if ( err.response.status === 401 ) {
        Message( { message: '访问该页面请先登录', type: 'error' } );
      } else if ( err.response.status === 403 ) {
        Message( { message: '令牌无效，请重新登录', type: 'error' } );
      } else {
        Message( { message: '系统错误', type: 'error' } );
      }
    }
  }
};

const onCover = async () => {
  if ( dataCheck( 'cover' ) ) {
    try {
      const res = await updateCapsulesApi( {
        title: paramsData.value.title,
        name: paramsData.value.name,
        content: paramsData.value.content,
        sendTime: paramsData.value.sendTime,
        id: paramsData.value.id,
        userId: paramsData.value.userId,
      } );
      if ( Number( res.code ) === 200 ) {
        Message( { message: res.message, type: 'success' } );
        onLoad();
      }
    } catch ( err ) {
      if ( err.response.status === 401 ) {
        Message( { message: '访问该页面请先登录', type: 'error' } );
      } else if ( err.response.status === 403 ) {
        Message( { message: '令牌无效，请重新登录', type: 'error' } );
      } else {
        Message( { message: '系统错误', type: 'error' } );
      }
    }
  }
};

const onDelete = async () => {
  try {
    const res = await deleteCapsulesApi( {
      id: paramsData.value.id,
      userId: paramsData.value.userId,
    } );
    if ( Number( res.code ) === 200 ) {
      Message( { message: res.message, type: 'success' } );
      onLoad();
    }
  } catch ( err ) {
    if ( err.response.status === 401 ) {
      Message( { message: '访问该页面请先登录', type: 'error' } );
    } else if ( err.response.status === 403 ) {
      Message( { message: '令牌无效，请重新登录', type: 'error' } );
    } else {
      Message( { message: '系统错误', type: 'error' } );
    }
  }
};

const onCloseModal = () => {
  emit( 'close' );
};

const onLoad = ( flag ) => {
  emit( 'load', flag );
};

onMounted( () => {
  document.body.style.overflowY = 'hidden';
} );

onUnmounted( () => {
  document.body.style.overflowY = 'auto';
} );
</script>
<style lang="less" scoped>
.form-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;

  .form-box {
    margin: 5% auto;
    width: 80%;
    height: 80%;
    background-color: @gray-10-8;
    backdrop-filter: blur(10px);
    position: relative;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .form-describe {
    width: 100%;

    .form {
      .flex-s;
      color: @gray-1;
      text-shadow: 1px 1px 1px @gray-1-2;

      .key {
        display: inline-block;
        width: 60px;
        text-align-last: justify;
      }

      .value {
        max-width: 80%;
        margin: 1px 0;

        .input {
          width: 160px;
          padding: 4px 10px;
          color: @gray-1;
          border-bottom: 1px solid @gray-10;
          background-color: transparent;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          box-shadow: inset 5px 1px 5px @gray-1-2;
          border-radius: 5px;
        }

        &::before {
          content: "：";
          color: @gray-1;
        }

        .disabled {
          background-color: @gray-3-4;
        }

        .radio {
          display: inline-block;
          cursor: pointer;
          font-size: 12px;
          padding: 2px 16px;
          background-color: @gray-3-4;
        }

        .radioActive {
          background-color: @success-color;
        }
      }
    }
  }

  .form-content {
    flex: 1;
    height: calc(100% - 131px);
    width: 100%;
    margin: 16px auto;
    color: @gray-1;
    text-align: left;
  }

  .form-submit {
    position: absolute;
    top: 2%;
    right: 3%;
    .flex;
    flex-direction: column;
    gap: 10px;
  }

  .pink {
    color: @warning-color !important;
  }

  ::v-deep {
    .ql-toolbar.ql-snow {
      padding: 2px;
    }

    .ql-container {
      height: calc(100% - 54px);
    }

    .ql-editor {
      font-size: 16px;
      height: 100%;

    }
  }

  .noclick {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>