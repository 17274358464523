<template>
  <div class="my-container" @click="onCloseModal">
    <div class="user-info" v-if="user">
      <div class="avatar" @click="openUserPanel">
        <img :src="user.avatar" v-if="user.avatar">
      </div>
      <div class="nickname" @click="openUserPanel">{{ user.nickname }}</div>
      <div id="lottieMX" v-if="showMingXiang"></div>
    </div>
    <div class="options" v-if="store.state.isMobile">
      <div class="about" @click="showProduct">关于</div>
      <div class="reward" @click="showTipping" v-if="webConfig.showBounty">支持</div>
      <div class="friend">
        <FriendlyLink :mobile="true"></FriendlyLink>
      </div>
    </div>
    <main class="main">
      <header class="header">
        <div class="item" :class="{ 'active': item.id === paramsData.flag }" v-for="item in navList" :key="item.id"
          @click="onToggleNav(item.id)">
          <span class="item-font">
            {{ item.name }}
            <div title="有新消息" class="have-notify-sub" v-if="isHaveNotify(item.type)"></div>
          </span>
        </div>
      </header>
      <div class="content" ref="content">
        <div :class="activeClass">
          <template v-if="activeClass === 'walls'">
            <NoteCard v-cloak v-for="(item, index) in list" :key="item.id" :note="item"
              :class="{ noteActive: index === activeNote }" @toDetail="onNoteClick(index)">
            </NoteCard>
          </template>

          <template v-else-if="activeClass === 'photos'">
            <PhotoCard class="photo-card" v-cloak v-for="(item, index) in list" :key="item.id" :photo="item"
              @toDetail="onNoteClick(index)">
            </PhotoCard>
          </template>

          <template v-else-if="activeClass === 'capsules'">
            <TimeCapsuleVue v-cloak v-for="(capsule, index) in list" :key="capsule.id" :capsule="capsule"
              :capsuleColor="_webConfig.capsuleColor" @toDetail="openModal(index)"></TimeCapsuleVue>
          </template>

          <template v-else-if="activeClass === 'channel'">
            <ExclusiveRoomVue v-cloak v-for="(item, index) in list" :key="item.id" :chatroom="item"
              :roomColor="_webConfig.roomColor" @toChatroom="onToChatroom(item.roomNumber, index)"></ExclusiveRoomVue>
          </template>
        </div>
        <div class="loading">
          <SGYShowDateStatus :isAnimation="isAnimation" :page="paramsData.page" :length="list.length" :top="100">
          </SGYShowDateStatus>
        </div>
        <SGYBtnAddRefresh :isRefresh="isRefresh" @onRefresh="onRefresh" :isMy="true"></SGYBtnAddRefresh>
      </div>
    </main>

    <SGYModal title="" :occupied="occupied" @close="onCloseModal" :isModal="isModal">
      <CardDetail v-if="activeNote != -1" :card="list[activeNote]" :photo="list[activeNote]"
        @alreadyDelete="onAlreadyDelete">
      </CardDetail>
    </SGYModal>

    <SGYViewer v-if="paramsData.type == 1 && !isMobile" :occupied="occupied" :isViewer="isViewer" :photos="photoUrls"
      :activeIndex="activeNote" @toggleViewer="onToggleViewer">
    </SGYViewer>

    <TimeCapsuleFormVue v-if="isModalCapsules" @close="onCloseModal" @load="onLoad" :capsule="list[activeIndex]"
      :type="paramsData.type" :flag="true">
    </TimeCapsuleFormVue>

    <div @click="openUserPanel">
      <UserPanel v-if="panelFlag" :open="panelFlag" :panelCoordinate="panelCoordinate" @closeDialog="onCloseDialog"
        @updateAvatar="onUpdateAvatar">
      </UserPanel>
    </div>
  </div>
</template>

<script>
export default { name: 'MyMessage' };
</script>
<script setup>
import lottie from 'lottie-web';
import mingxiang from '@/assets/json/mingxiang.json';
import NoteCard from "@/components/NoteCard.vue";
import PhotoCard from "@/components/PhotoCard.vue";
import TimeCapsuleVue from '@/components/TimeCapsule.vue';
import ExclusiveRoomVue from '@/components/ExclusiveRoom.vue';
import TimeCapsuleFormVue from '@/components/TimeCapsuleForm.vue';
import SGYShowDateStatus from '@/components/SGYShowDataStatus.vue';
import SGYBtnAddRefresh from '@/components/SGYBtnAddRefresh.vue';
import UserPanel from '@/components/UserPanel.vue';
import SGYModal from '@/components/SGYModal.vue';
import CardDetail from '@/components/CardDetail.vue';
import SGYViewer from '@/components/SGYViewer.vue';
import Message from '@/components/SGY/message/message';
import ShowDialog from '@/components/SGY/ShowDialog/ShowDialog';
import FriendlyLink from '@/components/FriendlyLink.vue';

import { getMyNotes, setMyNotes, getMyPhotos, setMyPhotos, getMyCapsules, setMyCapsules, getMyRooms, setMyRooms } from '@/store/sessionStorage';
import { findMyPublishDataApi, deleteNotifysApi } from "@/api/index";
import { onMounted, computed, ref, defineEmits, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();
const { getNotifyType, getNotifyData, findNotifysFn, webConfig, _webConfig } = inject( 'SGYindex' );
const emit = defineEmits( ['hideFooter'] );
const content = ref( null );
const showMingXiang = true;
const navList = [{ id: 1, type: 'note', name: '我的留言' }, { id: 2, type: 'photo', name: '我的照片' }, { id: 3, type: 'capsule', name: '我的胶囊' }, { id: 4, type: 'room', name: '我的房间' }];
const activeClass = ref( 'walls' );
const notifyType = ref( 'note' );
const list = ref( [] );
const activeNote = ref( -1 );
const isViewer = ref( false );
const isModal = ref( false );
const isModalCapsules = ref( false );
const occupied = ref( true ); // 弹窗高度占满
const activeIndex = ref( -1 );
const isPublicRefresh = ref( false ); // label数据刷新
const isAnimation = ref( -1 ); // 动画 -1 加载中 0 数据为空 1 无更多数据(加载完成)
const lastWallCardCall = ref( null ); // 上次加载时间
const isRefresh = ref( false ); // 刷新
const paramsData = ref( {
  flag: 1,
  userId: 0,
  type: 0,
  page: 1,
  pagesize: 10,
} );
const panelFlag = ref( false );
const panelCoordinate = ref( { x: 0, y: 0 } );

const user = computed( () => store.state.user );
const isMobile = computed( () => store.state.isMobile );
const photoUrls = computed( () => { // 图片墙数据
  return list.value.map( ( v ) => {
    if ( v && v.imgurl != null && v.imgurl != undefined ) {
      return v.imgurl;
    } else {
      return "";
    }
  } );
} );
paramsData.value.userId = user.value.id;

watch( () => router.currentRoute.value.path, ( nv ) => {
  if ( nv.includes( '/my' ) ) {
    let id = Number( nv.slice( 4, 5 ) );
    if ( id === 1 ) {
      activeClass.value = 'walls';
      notifyType.value = 'note';
      paramsData.value.type = 0;
    } else if ( id === 2 ) {
      activeClass.value = 'photos';
      notifyType.value = 'photo';
      paramsData.value.type = 1;
    } else if ( id === 3 ) {
      activeClass.value = 'capsules';
      notifyType.value = 'capsule';
    } else if ( id === 4 ) {
      activeClass.value = 'channel';
      notifyType.value = 'room';
    }
    paramsData.value.flag = id;
  }
}, { immediate: true } );

watch( () => paramsData.value.flag, () => {
  onCheckCachedData();
} );
const notifyHandle = () => {
  let result = getNotifyData( notifyType.value );
  if ( result && result.length > 0 ) {
    for ( let i = 0; i < list.value.length; i++ ) {
      for ( let j = 0; j < result.length; j++ ) {
        if ( result[j].productId == list.value[i].id ) {
          list.value[i].hasNotify = true;
        }
      }
    }
  }
};

const onToggleNav = ( id ) => {
  if ( paramsData.value.flag !== id ) {
    list.value = [];
    router.replace( `/my/${ id }` );
    content.value.scrollTop = 0;
    paramsData.value.page = 1;
  } else {
    // 清除消息
    deleteOneClassifyNotifysFn( Number( id ) );
  }
};

const findMyPublishDataFn = () => {
  let flag = throttle();
  if ( flag && paramsData.value.page > 0 ) {
    isAnimation.value = -1;
    findMyPublishDataApi( paramsData.value ).then( res => {
      if ( res.code === 200 ) {
        paramsData.value.page === 1 ? list.value = res.message : list.value.push( ...res.message );
        res.message.length === paramsData.value.pagesize ? paramsData.value.page++ : paramsData.value.page = 0;
      }
      notifyHandle();
      onSetCachedData();
    } ).finally( () => {
      list.value.length > 0 ? isAnimation.value = 1 : isAnimation.value = 0;
    } );
  }
};
// 存入缓存
const onSetCachedData = () => {
  switch ( Number( paramsData.value.flag ) ) {
    case 1: setMyNotes( paramsData.value.flag, { data: list.value, page: paramsData.value.page } );
      break;
    case 2: setMyPhotos( paramsData.value.flag, { data: list.value, page: paramsData.value.page } );
      break;
    case 3: setMyCapsules( paramsData.value.flag, { data: list.value, page: paramsData.value.page } );
      break;
    case 4: setMyRooms( paramsData.value.flag, { data: list.value, page: paramsData.value.page } );
      break;
  }
};
// 取出缓存
const onCheckCachedData = () => {
  let fnName;
  switch ( Number( paramsData.value.flag ) ) {
    case 1: fnName = getMyNotes;
      break;
    case 2: fnName = getMyPhotos;
      break;
    case 3: fnName = getMyCapsules;
      break;
    case 4: fnName = getMyRooms;
      break;
  }
  let result = fnName( paramsData.value.flag );
  if ( result && Array.isArray( result.data ) && typeof result.page == 'number' ) {
    list.value = result.data;
    paramsData.value.page = Number( result.page );
    list.value.length > 0 ? isAnimation.value = 1 : isAnimation.value = 0;
    notifyHandle();
  } else {
    findMyPublishDataFn();
  }
};

const throttle = () => {
  const now = Date.now();
  const lastCall = lastWallCardCall.value || 0;
  // 如果上次调用在0.3秒内，则不执行API调用  
  if ( now - lastCall < 300 ) {
    return false;
  } else {
    lastWallCardCall.value = now; // 更新上次调用时间  
    return true;
  }
};

const onRefresh = () => {
  isRefresh.value = true;
  content.value.scrollTop = 0;
  paramsData.value.page = 1;
  findMyPublishDataFn();
  setTimeout( () => {
    isRefresh.value = false;
    Message( { type: 'success', message: '刷新成功~~~' } );
  }, 500 );
};

const onToggleViewer = ( i ) => {
  if ( i == 0 ) {
    activeNote.value == 0 ? ( activeNote.value = list.value.length - 1 ) : activeNote.value--;
  } else {
    ( activeNote.value == list.value.length - 1 ) ? activeNote.value = 0 : activeNote.value++;
  }
};

const onAlreadyDelete = ( id ) => {
  list.value = list.value.filter( v => v.id != id );
  if ( paramsData.value.type == 0 ) { // 留言
    isModal.value = false;
    activeNote.value = -1;
  } else { // 照片
    isViewer.value = false;
  }
};

const onNoteClick = ( i ) => { // 切换弹窗
  if ( list.value[i]?.hasNotify ) {
    deleteOneNotifysFn( i );
  }
  if ( activeNote.value != i ) {
    activeNote.value = i;
    if ( typeof i == 'object' ) {
      if ( i.imgurl != '' ) isViewer.value = true;
    } else {
      if ( paramsData.value.type == 1 ) isViewer.value = true;
    }
    isModal.value = true;
  } else {
    activeNote.value = -1;
    if ( paramsData.value.type == 1 ) isViewer.value = false;
    isModal.value = false;
  }
};

const onCloseModal = ( data ) => {
  isModal.value = false;
  isViewer.value = false;
  isModalCapsules.value = false;
  activeNote.value = -1;
  document.body.style.overflowY = 'auto';
  if ( !data ) console.log( "close" );
};

const openModal = ( index ) => {
  if ( list.value[index]?.hasNotify ) {
    deleteOneNotifysFn( index );
  }
  if ( index || index === 0 ) {
    activeIndex.value = index;
  }
  isModalCapsules.value = true;
};

const onToChatroom = ( roomNumber, index ) => {
  emit( 'hideFooter' );
  router.push( `/channelDetail/${ roomNumber }` );
  deleteOneNotifysFn( index );
};

const onLoad = ( flag ) => {
  if ( flag == 1 ) { isPublicRefresh.value = true; }
  paramsData.value.page = 1;
  findMyPublishDataFn();
  onCloseModal();
};

const startAnimation = () => {
  const params = {
    container: document.getElementById( 'lottieMX' ),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: mingxiang,
  };
  lottie.loadAnimation( params );
};

const contentScroll = ( e ) => {
  let scrollTop = Math.ceil( e.target.clientHeight + e.target.scrollTop ) + 150;
  let clientHeight = e.target.scrollHeight;
  if ( scrollTop > clientHeight ) {
    paramsData.value.page > 0 && findMyPublishDataFn();
  }
};

const showProduct = () => {
  ShowDialog( {
    'type': 'fullScreen',
    'title': '关于产品',
    'message': `
      <div style="text-align:left;">
        <p style="margin-bottom:6px;font-size:14px">一、产品介绍</p>
        <div  style='font-size:12px'>
          <p>长卷阁是由个人开发的，为便于与用户交流的社交平台。</p>
          <p>用户可轻松贴上个性化便签，自由设置颜色和内容。留言墙不仅用于交流分享，更是记录生活点滴的好地方。</p>
          <p>用户可以种下时光胶囊，为未来的自己或亲友留下珍贵留言。设定胶囊的开启时间，期待与回忆交织，感受时间的美好。</p>
          <p>用户可快速创建私密聊天房间，与好友进行一对一交流。同时，大厅提供开放的交流空间，与众多人分享心得与想法。</p>
        </div>
        <p style="margin:12px 0 6px 0;font-size:14px">二、免责声明</p>
        <div style='font-size:12px'>
          <p>长卷阁由本人开发的，为便于与用户交流的留言平台。请不要利用此平台服务制作、上传、下载、复制、发布、传播或者转载如下内容：</p>
          <p>1、反对宪法所确定的基本原则的</p>
          <p>2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
          <p>3、损害国家荣誉和利益的；</p>
          <p>4、煸动民族仇恨、民族歧视，破坏民族团结的</p>
          <p>5、破坏国家宗教政策，宣扬邪教和封建迷信的</p>
          <p>6、散布谣言，扰乱社会秩序，破坏社会稳定的</p>
          <p>7、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的</p>
          <p>8、侮辱或者诽谤他人，侵害他人合法权益的；</p>
          <p>9、含有法律、行政法规禁止的其他内容的信息。</p>
        </div>
        <p style="margin:12px 0 6px 0;font-size:14px">三、政策协议</p>
        <div style='font-size:12px'>
          <p>请点击查看&nbsp;<a style="color: skyblue" href="https://www.1.lxlike.top/policy/service_agreement.html" target="_blank">服务协议</a>&nbsp;及&nbsp;<a style="color: skyblue" href="https://www.1.lxlike.top/policy/privacy_policy.html" target="_blank">隐私政策</a></p> 
          <p>备案: <a style="color: skyblue" href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2024042758号-1</a></p>
        </div>
      </div>`
  } );
};

const showTipping = () => {
  ShowDialog( {
    'type': 'fullScreen',
    'currentType': 'Tipping',
    'title': '支持产品',
    'message': `
      <div style="text-align:left;">
        <div  style='font-size:14px'>
          <p style='text-indent:2em;'>欢迎各位使用本产品！如果您对我们的产品感到满意，并希望支持我们的持续开发与优化，请在下方进行支持。我们深知，每一份支持都是对我们工作最大的鼓励和动力。</p>
          <p style='text-indent:2em;'>本产品将持续进行维护与优化，力求为大家带来更加出色的体验。我们坚信，通过不断的努力和改进，我们的产品将更加符合您的期待和需求</p>
        </div>
      </div>`
  } );
};

// 是否有通知消息
const isHaveNotify = ( type ) => {
  let notifys = getNotifyType();
  if ( notifys && Array.isArray( notifys ) ) {
    return notifys.includes( type );
  }
  return false;
};

// 删除单个通知消息
const deleteOneNotifysFn = async ( i ) => {
  try {
    let res = await deleteNotifysApi( { productId: list.value[i].id, userId: user.value.id } );
    if ( res.code === 200 ) {
      delete list.value[i].hasNotify;
      onSetCachedData();
      findNotifysFn();
    }
  } catch ( error ) {
    console.log( error );
  }
};

// 删除单类型通知消息
const deleteOneClassifyNotifysFn = async ( i ) => {
  try {
    let type = i === 1 ? 'note' : i === 2 ? 'photo' : i === 3 ? 'capsule' : 'room';
    let res = await deleteNotifysApi( { type: type, userId: user.value.id } );
    if ( res.code === 200 ) {
      list.value.forEach( f => delete f.hasNotify );
      onSetCachedData();
      findNotifysFn();
    }
  } catch ( error ) {
    console.log( error );
  }
};
const openUserPanel = ( e ) => {
  panelCoordinate.value = { x: e.clientX, y: e.clientY };
  panelFlag.value = !panelFlag.value;
};
const onUpdateAvatar = ( url ) => {
  user.value.avatar = url;
};
const onCloseDialog = () => {
  panelFlag.value = false;
  this.$emit( 'closeDialog' );
};

onMounted( () => {
  startAnimation();
  if ( route.query?.r ) {
    findMyPublishDataFn();
    router.push( { query: {} } );
  } else {
    onCheckCachedData();
  }
  content.value.addEventListener( 'scroll', contentScroll );
} );
</script>
<style lang="less" scoped>
.my-container {
  position: relative;
  width: 100%;
  padding: 20px 60px;

  .user-info {
    position: relative;
    width: 100%;
    .flex-s;
    gap: 20px;
    backdrop-filter: blur(10px);
    background-color: @gray-10-4;
    border-bottom: 1px solid @gray-1-6;
    padding: 20px;

    .avatar {
      width: 80px;
      height: 80px;
      line-height: 100px;
      text-align: center;
      background-color: @gray-3;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .nickname {
      max-width: 50%;
      font-size: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    #lottieMX {
      width: 100px;
      margin-left: auto;
    }
  }

  .options {
    width: 100%;
    background-color: @gray-10-4;
    backdrop-filter: blur(10px);
    border-bottom: 1px solid @gray-1-6;
    .flex-s;

    &>div {
      padding: 14px 0;
      text-align: center;
      letter-spacing: 2px;
      cursor: pointer;
    }

    .reward,
    .about {
      width: auto;
      padding-left: 18px;
    }

    .friend {
      flex: 1;
      overflow: hidden;
    }
  }

  .main {
    width: 100%;
    padding-bottom: 20px;
    background-color: @gray-10-4;
    backdrop-filter: blur(10px);
    border-bottom: 1px solid @gray-1-6;

    .header {
      .flex-s;
      width: 100%;

      &>.item {
        position: relative;
        padding: 20px 30px;
        text-align: center;
        letter-spacing: 2px;
        cursor: pointer;

        .item-font {
          position: relative;

          .have-notify-sub {
            transform: translate(-5px, -5px);
            box-shadow: unset;
          }
        }
      }
    }

    .content {
      position: relative;
      width: 100%;
      height: 60vh;
      padding: 20px 20px 100px 20px;
      overflow: auto;

      .loading {
        display: block;
        width: 100%;
      }

      .walls {
        margin: 0 auto;
        width: 96%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        grid-gap: 10px 10px;
        cursor: pointer;
        transition: @tr;
      }

      .photos {
        margin: 0 auto;
        width: 96%;
        column-count: 5;
        column-gap: @padding-4;
        column-fill: balance;

        .photo-card {
          padding-top: 3px;
          break-inside: avoid;
          margin-bottom: @padding-4;
        }
      }

      .noteActive {
        box-shadow: 0 10px 10px 5px rgba(0, 0, 0, 0.2);
        transform: translateY(-5px);
      }

      .capsules {
        margin: 0 auto;
        padding: 1vw 0;
        width: 96%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
        grid-gap: 10px 10px;
        cursor: pointer;
        transition: @tr;
      }

      .channel {
        margin: 0 auto;
        padding: 1vw 0;
        width: 96%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
        grid-gap: 10px 10px;
        cursor: pointer;
        transition: @tr;
      }
    }
  }

  .active {
    background-color: @gray-3-2;
  }
}
</style>