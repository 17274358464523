<template>
  <!-- 刷新 -->
  <div title="刷新" class="refresh" :class="props.isRefresh && 'refreshing'"
    :style="{ 'bottom': addBottom + 45 + 'px', background: buttonColor }" @click.stop="refresh()" v-show="!isModal">
    <span class="iconfont icon-shuaxin1"></span>
    {{ props.buttonColor }}
  </div>
  <!-- 发布 -->
  <div title="发布" class="add" :style="{ 'bottom': addBottom + 'px', background: buttonColor }" @click.stop="modal()"
    v-show="!isMy && !isModal">
    <span class="iconfont icon-tianjia"></span>
  </div>
</template>

<script>
export default { name: 'SGYShowDataStatus' };
</script>
<script setup>
import { defineProps, defineEmits, ref, onMounted, onUnmounted } from 'vue';
import { getWebConfig } from '@/store/localStorage';
import eventBus from '@/utils/eventBus';

const buttonColor = ref( 'rgba(0,0,0,0.8)' );
const props = defineProps( { isRefresh: { type: Boolean, default: false }, addBottom: { type: Number, default: 25 }, activeNote: { type: Number, default: 0 }, isModal: { type: Boolean, default: false }, isMy: { type: Boolean, default: false } } );
const emit = defineEmits( ['onRefresh', 'openModal'] );

const refresh = () => emit( 'onRefresh' );
const modal = () => emit( 'openModal' );

const onWebConfigUpdate = ( value ) => {
  buttonColor.value = value.buttonColor;
};

onUnmounted( () => {
  eventBus.$off( 'webConfig', onWebConfigUpdate );
} );
onMounted( async () => {
  buttonColor.value = await getWebConfig( 4 );
  eventBus.$on( 'webConfig', onWebConfigUpdate );
} );
</script>
<style lang="less" scoped>
.add,
.refresh {
  width: 42px;
  height: 42px;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  text-align: center;
  position: fixed;
  right: 30px;
  cursor: pointer;
  transition: @tr;
  z-index: 80;

  &>span {
    font-size: 24px;
    color: @gray-10;
  }
}

.refreshing {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>