<template>
  <div class="capsule-container" @click.stop="openModal">
    <div class="top">
      <div class="toEmail">{{ capsule.email }}</div>
      <div class="level">
        <span :class="capsule.type ? 'public' : 'private'">{{ capsule.type ? '公开' : '保密' }}</span>
      </div>
    </div>
    <div class="main">
      <div class="toDate">
        <span class="key">TO</span>: {{ formatDate(capsule.sendTime) }}
      </div>
      <div class="title">
        <div>主题:&nbsp;</div>
        <div class="strip-title">{{ capsule.title }}</div>
      </div>
      <div class="userName">
        <div>签名:&nbsp;</div>
        <div class="strip-title">{{ capsule.name }}</div>
        <div class="pickupCode" v-if="capsule.pickupCode" @click.stop>
          取件码&nbsp;{{ capsule.pickupCode }}
          <i class="iconfont icon-fuzhi" @click.stop="onCopyRoomKey(capsule.pickupCode)"></i>
        </div>
      </div>
      <div class="fromDate">
        <svg v-if="capsule.status == 0" t="1712339398182" class="icon hourglass" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" p-id="1366" width="24" height="24">
          <path
            d="M327.68 196.608h375.808L592.896 375.808l69.632 43.008 148.48-241.664-34.816-62.464H247.808l-33.792 64.512 253.952 350.208 226.304 297.984H320.512L430.08 649.216l-69.632-43.008-147.456 240.64 34.816 62.464h528.384l32.768-65.536L534.528 481.28z"
            fill="#437DFF" p-id="1367"></path>
          <path
            d="M513.024 229.376h-78.848l78.848 93.184 77.824-93.184zM513.024 794.624h-78.848l78.848-93.184 77.824 93.184z"
            fill="#63F7DE" p-id="1368"></path>
        </svg>
        <span class="key">FROM</span>: {{ formatDate(capsule.createTime) }}
      </div>
      <i :class="capsule.status ? 'send2' : 'send1'"></i>
    </div>

    <div title="胶囊已发送" class="have-notify-sub" v-if="capsule.hasNotify"></div>

    <div class="back-image" :style="{ 'background': props.capsuleColor }"></div>
  </div>
</template>

<script>
export default { name: 'TimeCapsule' };
</script>
<script setup>
import { formatDate, onCopyRoomKey } from '@/utils/handleFn';
import { defineEmits, defineProps, computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps( { capsule: Object, capsuleColor: String } );
const emit = defineEmits( ['toDetail'] );
const store = useStore();

const capsule = computed( () => props.capsule );

const openModal = ( index ) => {
  if ( ( store.state.user && store.state.user.id === Number( capsule.value.userId ) ) || capsule.value.type === 1 ) {
    emit( 'toDetail', index );
  }
};
</script>
<style lang="less" scoped>
.capsule-container {
  width: 100%;
  .flex;
  flex-direction: column;
  position: relative;

  .back-image {
    content: '';
    position: absolute;
    top: 6%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: @linear-1-w;
    filter: blur(30px);
  }

  .top {
    width: 100%;
    height: 20px;
    border-radius: 20px;
    border: 1px solid @gray-8;
    background-color: @gray-10-4;
    backdrop-filter: blur(10px);
    z-index: 5;
    .flex-b;

    .toEmail {
      max-width: 70%;
      font-size: 14px;
      text-align: left;
      padding: 0 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .level {

      .private,
      .public {
        padding: 0 10px;
        font-size: 14px;
        color: @gray-10;
        font-weight: 550;
        letter-spacing: 2px;
      }

      .public {
        color: @primary-color;
      }

      .private {
        color: @warning-color;
      }
    }
  }

  .main {
    width: 95%;
    margin: 0 auto;
    padding: 5px 10px;
    border-radius: 0 0 20px 20px;
    border: 1px solid @gray-8;
    background-color: @gray-10-2;
    backdrop-filter: blur(10px);
    z-index: 1;

    .toDate {
      text-align: left;
      font-size: 12px;
    }

    .title,
    .userName {
      width: 100%;
      padding: 5px;
      font-size: 14px;
      text-align: left;
      .flex-s;

      .strip-title {
        max-width: 80%;
        font-weight: 550;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: @warning-color;
      }
    }

    .userName {
      padding: 0 0 5px 10px;

      .strip-title {
        max-width: 50%;
        color: unset;
      }
    }

    .fromDate {
      width: 100%;
      text-align: right;
      position: relative;
      font-size: 12px;
      padding: 2px 0;

      .hourglass {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 50% 50%;
        animation: hourglass 3s linear infinite;
      }
    }

    @keyframes hourglass {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .send1,
    .send2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-size: 100px 26px;
      background-repeat: no-repeat;
      background-position: 90% 30%;
      // transform: rotateZ(-25deg);
    }

    .send1 {
      background-image: url('~@/assets/images/send/send1.png');
    }

    .send2 {
      background-image: url('~@/assets/images/send/send2.png');
      background-size: 80px 26px;
    }

    .pickupCode {
      .copyable;
      z-index: 20;
      font-size: 12px;
      font-weight: 400;
      margin-left: auto;

      .icon-fuzhi {
        margin-left: 2px;
      }
    }
  }
}
</style>