// 墙的性质
export const wallType = [
  {
    name: '留言墙',
    slogan: '生活中，无数的事情如同璀璨的星辰，闪烁着它们独特的光芒，值得我们细细记录。每一份欢笑、每一滴泪水、每一个瞬间，都承载着我们的情感与记忆，构成了我们丰富多彩的人生画卷。'
  },
  {
    name: '照片墙',
    slogan: '当我们按下快门的那一刻，无论是山川的壮丽、城市的繁华，还是人物的喜怒哀乐，都被定格在了那一方小小的相纸之上。这些照片，不仅记录了我们的所见所闻，更承载了我们的情感与记忆。'
  }
];

// 导航菜单
export const menu = [
  { label: '留言', type: 'note', path: '/' },
  { label: '胶囊', type: 'photo', path: '/email' },
  { label: '频道', type: 'capsule', path: '/channel' },
  { label: '我的', type: 'room', path: `/my/${ 1 }` }
];

// 分类标签
export const labels = [
  ['寄语', '志向', '愿景', '往昔', '未来', '情爱', '亲恩', '情谊', '秘事', '信仰', '无名'],
  ['吾言', '伊人', '挚爱', '雅趣', '追忆', '故校', '生涯', '苍穹', '都市', '瀚海', '无名']
];

// 卡片颜色
export const cardColor = [
  'rgba(252, 175, 162, 0.4)',
  'rgba(255, 227, 148, 0.4)',
  'rgba(146, 230, 245, 0.4)',
  'rgba(168, 237, 138, 0.4)',
  'rgba(202, 167, 247, 0.4)',
  'rgba(212, 212, 212, 0.4)',
];

// 选择方块颜色
export const pieceColor = [
  'rgba(252, 175, 162, 1)',
  'rgba(255, 227, 148, 1)',
  'rgba(146, 230, 245, 1)',
  'rgba(168, 237, 138, 1)',
  'rgba(202, 167, 247, 1)',
];

// 头像背景
export const portrait = [
  'linear-gradient(135deg, #f9e79f, #f7dc7f, #f4d05f, #f0c43f, #ecc71f)',
  'linear-gradient(135deg, #e8c3d3, #d7a7c3, #c58ab2, #b46ea2, #a25091)',
  'linear-gradient(135deg, #f4b4a1, #f0907f, #ec6c5e, #e8483d, #e4241c)',
  'linear-gradient(135deg, #d3e8c3, #c3dcc7, #b3cfbb, #a3c7af, #93bea3)',
  'linear-gradient(135deg, #dcebf7, #cad7f4, #b8c0f0, #a5aafc, #9294f8)',
  'linear-gradient(135deg, #f9d0e8, #f5b0d7, #f090c7, #ec70b6, #e850a6)',
  'linear-gradient(135deg, #e8f4c7, #d7f0b7, #c5ecb6, #b4e8a6, #a2e495)',
  'linear-gradient(135deg, #f4cde8, #f0b0d7, #ec93c7, #e875b6, #e457a6)',
  'linear-gradient(135deg, #e8f9d3, #d7f4c3, #c5efb2, #b4eab2, #a2e5a1)',
  'linear-gradient(135deg, #dcefeb, #c7e7f7, #b0dff4, #9ad7f0, #82cefc)',
  'linear-gradient(135deg, #f9e4d3, #f4c9b3, #efbe92, #eacc72, #e5bc51)',
  'linear-gradient(135deg, #e8d7f9, #d7caf4, #c5bdf0, #b4affb, #a2a0f7)',
  'linear-gradient(135deg, #f4e8c7, #f0d0b6, #eccab6, #e8c4a5, #e4be95)',
  'linear-gradient(135deg, #dcebf9, #c7e7f4, #b0dff0, #9ad7eb, #82cef7)',
  'linear-gradient(135deg, #f9dcef, #f4bcd7, #efb6c3, #eac0af, #e6aab9)',
  'linear-gradient(135deg, #e8f4d9, #d7f0cb, #c5ecc6, #b4e8b3, #a2e4a0)',
  'linear-gradient(135deg, #dcf4eb, #c7e8f0, #b0dbf7, #9ad1fc, #82c7fe)',
  'linear-gradient(135deg, #f9e0d7, #f4c0b7, #ef9f96, #eb7f76, #e75f55)',
  'linear-gradient(135deg, #e8dcf4, #d7cbf0, #c5b7eb, #b4a3e6, #a290e2)',
  'linear-gradient(135deg, #e8dcf4, #d7cbf0, #c5b7eb, #b4a3e6, #a290e2)',
  'linear-gradient(135deg, #f4e8e0, #f0d8c0, #eccfb0, #e8c7a0, #e4c090)',
  'linear-gradient(135deg, #dcf9e8, #c7f4e0, #b0f0d0, #9addc0, #82d7b0)',
  'linear-gradient(135deg, #f9dce8, #f4c3d7, #efb6c7, #eac0b6, #e6aab5)',
  'linear-gradient(135deg, #c7e9fb, #a6defa, #80d4f9, #5bc9f8, #35bef7)'
];

import TX1 from "@/assets/avarat/TX-1.jpeg";
import TX2 from "@/assets/avarat/TX-2.jpeg";
import TX3 from "@/assets/avarat/TX-3.jpeg";
import TX4 from "@/assets/avarat/TX-4.jpeg";
import TX5 from "@/assets/avarat/TX-5.jpeg";
import TX6 from "@/assets/avarat/TX-6.jpeg";
import TX7 from "@/assets/avarat/TX-7.jpeg";
import TX8 from "@/assets/avarat/TX-8.jpeg";
import TX9 from "@/assets/avarat/TX-9.jpeg";
import TX10 from "@/assets/avarat/TX-10.jpeg";
import TX11 from "@/assets/avarat/TX-11.jpeg";
import TX12 from "@/assets/avarat/TX-12.jpeg";
import TX13 from "@/assets/avarat/TX-13.jpeg";
import TX14 from "@/assets/avarat/TX-14.jpeg";
import TX15 from "@/assets/avarat/TX-15.jpeg";
import TX16 from "@/assets/avarat/TX-16.jpeg";
import TX17 from "@/assets/avarat/TX-17.jpeg";
import TX18 from "@/assets/avarat/TX-18.jpeg";
import TX19 from "@/assets/avarat/TX-19.jpeg";
import TX20 from "@/assets/avarat/TX-20.jpeg";
import TX21 from "@/assets/avarat/TX-21.jpeg";
import TX22 from "@/assets/avarat/TX-22.jpeg";
import TX23 from "@/assets/avarat/TX-23.jpeg";
import TX24 from "@/assets/avarat/TX-24.jpeg";
import TX25 from "@/assets/avarat/TX-25.jpeg";
import TX26 from "@/assets/avarat/TX-26.jpeg";
import TX27 from "@/assets/avarat/TX-27.jpeg";
import TX28 from "@/assets/avarat/TX-28.jpeg";
import TX29 from "@/assets/avarat/TX-29.jpeg";
import TX30 from "@/assets/avarat/TX-30.jpeg";
import TX31 from "@/assets/avarat/TX-31.jpeg";
import TX32 from "@/assets/avarat/TX-32.jpeg";
import TX33 from "@/assets/avarat/TX-33.jpeg";
import TX34 from "@/assets/avarat/TX-34.jpeg";
import TX35 from "@/assets/avarat/TX-35.jpeg";
import TX36 from "@/assets/avarat/TX-36.jpeg";
import TX37 from "@/assets/avarat/TX-37.jpeg";
import TX38 from "@/assets/avarat/TX-38.jpeg";
import TX39 from "@/assets/avarat/TX-39.jpeg";
import TX40 from "@/assets/avarat/TX-40.jpeg";
import TX41 from "@/assets/avarat/TX-41.jpeg";
import TX42 from "@/assets/avarat/TX-42.jpeg";
import TX43 from "@/assets/avarat/TX-43.jpeg";
import TX44 from "@/assets/avarat/TX-44.jpeg";
import TX45 from "@/assets/avarat/TX-45.jpeg";
import TX46 from "@/assets/avarat/TX-46.jpeg";
import TX47 from "@/assets/avarat/TX-47.jpeg";
import TX48 from "@/assets/avarat/TX-48.jpeg";
import TX49 from "@/assets/avarat/TX-49.jpeg";
import TX50 from "@/assets/avarat/TX-50.jpeg";
import TX51 from "@/assets/avarat/TX-51.jpeg";
import TX52 from "@/assets/avarat/TX-52.jpeg";
import TX53 from "@/assets/avarat/TX-53.jpeg";
import TX54 from "@/assets/avarat/TX-54.jpeg";
import TX55 from "@/assets/avarat/TX-55.jpeg";
import TX56 from "@/assets/avarat/TX-56.jpeg";
import TX57 from "@/assets/avarat/TX-57.jpeg";
import TX58 from "@/assets/avarat/TX-58.jpeg";
import TX59 from "@/assets/avarat/TX-59.jpeg";
import TX60 from "@/assets/avarat/TX-60.jpeg";
import TX61 from "@/assets/avarat/TX-61.jpeg";
import TX62 from "@/assets/avarat/TX-62.jpeg";
import TX63 from "@/assets/avarat/TX-63.jpeg";
import TX64 from "@/assets/avarat/TX-64.jpeg";
import TX65 from "@/assets/avarat/TX-65.jpeg";
import TX66 from "@/assets/avarat/TX-66.jpeg";
import TX67 from "@/assets/avarat/TX-67.jpeg";
import TX68 from "@/assets/avarat/TX-68.jpeg";
import TX69 from "@/assets/avarat/TX-69.jpeg";
import TX70 from "@/assets/avarat/TX-70.jpeg";
import TX71 from "@/assets/avarat/TX-71.jpeg";
import TX72 from "@/assets/avarat/TX-72.jpeg";
import TX73 from "@/assets/avarat/TX-73.jpeg";
import TX74 from "@/assets/avarat/TX-74.jpeg";
import TX75 from "@/assets/avarat/TX-75.jpeg";
import TX76 from "@/assets/avarat/TX-76.jpeg";
import TX77 from "@/assets/avarat/TX-77.jpeg";
import TX78 from "@/assets/avarat/TX-78.jpeg";
import TX79 from "@/assets/avarat/TX-79.jpeg";
import TX80 from "@/assets/avarat/TX-80.jpeg";
import TX81 from "@/assets/avarat/TX-81.jpeg";
import TX82 from "@/assets/avarat/TX-82.jpeg";
import TX83 from "@/assets/avarat/TX-83.jpeg";
import TX84 from "@/assets/avarat/TX-84.jpeg";
import TX85 from "@/assets/avarat/TX-85.jpeg";
import TX86 from "@/assets/avarat/TX-86.jpeg";
// 头像
export const portraitList = [TX1, TX2, TX3, TX4, TX5, TX6, TX7, TX8, TX9, TX10, TX11, TX12, TX13, TX14, TX15, TX16, TX17, TX18, TX19, TX20, TX21, TX22, TX23, TX24, TX25, TX26, TX27, TX28, TX29, TX30, TX31, TX32, TX33, TX34, TX35, TX36, TX37, TX38, TX39, TX40, TX41, TX42, TX43, TX44, TX45, TX46, TX47, TX48, TX49, TX50, TX51, TX52, TX53, TX54, TX55, TX56, TX57, TX58, TX59, TX60, TX61, TX62, TX63, TX64, TX65, TX66, TX67, TX68, TX69, TX70, TX71, TX72, TX73, TX74, TX75, TX76, TX77, TX78, TX79, TX80, TX81, TX82, TX83, TX84, TX85, TX86];

// 友链
import Logo from "@/assets/logo.png";
import LiuZiXu from "@/assets/links/LiuZiXu.png";
export const friendlyLinks = [
  {
    userName: 'Lxlike',
    title: '长卷阁',
    link: 'http://www.lxlike.top',
    image: Logo
  },
  {
    userName: 'LiuZiXu',
    title: '劉のBlog',
    link: 'https://groming.github.io/',
    image: LiuZiXu
  }
];