<template>
  <div v-if="open" class="dialog-mask">
    <dialog @click.stop :open="open" class="popup-before" :class="{ 'div-dialog': isOpen }"
      :style="panelCoordinate && `top:${panelCoordinate.y}px;left:${panelCoordinate.x}px`">
      <div v-show="isWaitPage">
        <div class="box" v-show="isLogin">
          <div class="user-info">
            <div class="nick-name">
              <div class="name" title="点击可修改名称" @click="onShowNameEdit">
                {{ queryParams.nickname != '' ? queryParams.nickname : '未设置昵称' }}
              </div>
              <input ref="nameRef" class="name-input" type="text" v-model="queryParams.nickname"
                v-show="isNickNameEdit">
              <i class="iconfont icon-chenggongyijiejue" title="点击确认修改" v-show="isNickNameEdit"
                @click="updateUserInfoData('name')"></i>
            </div>
            <div class="avatar" title="点击可修改头像">
              <div class="current-img">
                <img v-if="queryParams.avatar != ''" :src="queryParams.avatar" @click="isAvatarEdit = !isAvatarEdit">
                <div class="div-notImg" v-else @click="isAvatarEdit = !isAvatarEdit"></div>
              </div>
              <div class="overflow-img" ref="overflowImgRef" v-show="isAvatarEdit">
                <div class="img-list">
                  <div class="current-img" v-for=" item in portraitList " :key="item">
                    <img :src="item" @click="onSelectAvatar(item)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SGYButton medium line @click="logout">退出登录</SGYButton>
        </div>
        <div class="box" v-show="!isLogin">
          <SGYButton medium line @click="goLogin">登录</SGYButton>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
export default { name: 'UserPanel' };
</script>
<script setup>
/** 导入 */
import { defineProps, ref, onMounted, defineEmits, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { updateUserInfoApi, getUserInfoApi, logoutApi } from '@/api/index';
import SGYButton from './SGYButton.vue';
import Message from '@/components/SGY/message/message';
import { portraitList } from "@/utils/data";
import { removeAccessToken, removeRefreshToken } from "@/store/localStorage";
import { removeData } from "@/store/sessionStorage";

/** 实例化 */
defineProps( ['open', 'panelCoordinate'] );
const emit = defineEmits( ['updateAvatar', 'closeDialog'] );
const router = useRouter();
const store = useStore();
const overflowImgRef = ref( '' );

/** 变量 */
const isOpen = ref( false ); // 登录状态
const isWaitPage = ref( false ); // 等待dom加载
const isNickNameEdit = ref( false ); // 昵称修改
const isAvatarEdit = ref( false ); // 头像修改
const nameRef = ref( null ); // 昵称输入框
const queryParams = ref( {
  id: '::-1',
  avatar: '',      // 头像
  nickname: '',  // 昵称
} );

/** 计算属性 */
const isLogin = computed( () => {
  return store.state.user != null ? true : false;
} );
const userId = computed( () => {
  return store.state.user?.id || '::-1';
} );

// 侦听器
watch( isOpen, () => {
  if ( isOpen.value ) {
    setTimeout( () => isWaitPage.value = true, 100 );
  }
}, { immediate: true } );

/** 函数 */
const logout = async () => {
  // 展示不需要请求接口
  await logoutApi().then( ( response ) => {
    removeAccessToken();
    removeRefreshToken();
    removeData();
    store.commit( 'setUser', null );
    Message( { type: 'success', message: response.message } );
  } );
  emit( 'closeDialog' );
};

const goLogin = () => {
  emit( 'closeDialog' );
  if ( store.state && store.state.isMobile ) {
    router.replace( {
      path: 'LoginH5'
    } );
  } else {
    router.replace( {
      path: 'LoginPc'
    } );
  }
};

const onShowNameEdit = () => {
  if ( isNickNameEdit.value == false ) {
    isNickNameEdit.value = true;
    setTimeout( () => {
      nameRef.value.focus();
    }, 100 );
  } else {
    isNickNameEdit.value = false;
  }
};

const onSelectAvatar = ( url ) => {
  if ( url && url == '' ) {
    Message( { type: 'error', message: '请选择头像' } );
    return false;
  }
  queryParams.value.avatar = url;
  updateUserInfoData();
};

/** 修改头像和昵称 */
const updateUserInfoData = async ( type ) => {
  if ( type == 'name' && queryParams.value.nickname == '' ) {
    Message( { type: 'error', message: '请输入昵称' } );
    return false;
  }
  queryParams.value.id = userId.value;
  await updateUserInfoApi( queryParams.value ).then( () => {
    getUserInfoData();
  } );
};

/** 获取用户信息 */
const getUserInfoData = async () => {
  await getUserInfoApi( { id: userId.value } ).then( ( res ) => {
    store.commit( 'setUser', res.message[0] );
    if ( isAvatarEdit.value ) emit( 'updateAvatar', queryParams.value.avatar );
    Message( { type: 'success', message: '修改成功' } );
    isNickNameEdit.value = false;
    isAvatarEdit.value = false;
  } );
};

/** 钩子 */
onMounted( () => {
  setTimeout( () => isOpen.value = open, 10 );
  if ( store.state.user != '' && store.state.user != null ) {
    queryParams.value = {
      id: store.state.user?.id || '::-1',
      avatar: store.state.user?.avatar || '',
      nickname: store.state.user?.nickname || '',
    };
  }

  // 去除滑动事件 - 适配移动端滑动
  overflowImgRef.value.addEventListener( 'touchmove', ( e ) => e.stopPropagation(), false );
} );
</script>
<style lang="less" scoped>
.popup-before {
  position: fixed;
  top: 50%;
  left: calc(100% - 105px); // 105px弹窗的宽度
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  z-index: -1;
  border: none;
  transition: all 0.1s;
  color: transparent;
}

.div-dialog {
  position: fixed;
  top: calc(100% + 16px);
  left: calc(100% - 170px); // 105px弹窗的宽度 + 64px头像
  width: 105px;
  height: auto;
  border: none;
  padding: @padding-20;
  border-radius: 20px;
  background: @gray-10-8;
  box-shadow: 0 0 10px 0 @gray-0-4;
  backdrop-filter: blur(10px);
  z-index: 1;
  animation: example 0.5s ease-in-out;
  color: @gray-1;
  box-sizing: content-box;
}

@keyframes example {
  50% {
    transform: scale(1.05);
  }

  75% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.box {
  .flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.user-info {
  width: 100%;
  margin-bottom: @padding-20;

  .nick-name+.avatar {
    margin-top: 10px;
  }

  .nick-name {
    position: relative;
    padding: 5px;
    border-bottom: 1px solid @gray-2;

    .name-input {
      width: 100%;
      padding: 0 5px;
      box-sizing: border-box;
      line-height: 26px;
      background-color: rgba(0, 0, 0, 0.2);
      font-size: @size-14;
      color: @gray-10;
      transition: @tr;
    }

    .iconfont {
      position: absolute;
      top: -6px;
      right: 0;
      font-size: 20px;
      color: @gray-3;
    }

    .name {
      transform-origin: center;
      transition: @tr;

      &:hover {
        font-size: 16px;
      }
    }
  }

  .current-img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 5px;
    background-color: @gray-9;

    img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      object-fit: cover;
      transition: @tr;

      &:hover {
        transform: scale(1.1);
      }
    }

    .div-notImg {
      width: 100%;
      height: 100%;
    }
  }

  .avatar {
    width: 100%;
    overflow: hidden;
  }

  .overflow-img {
    width: calc(100% + 12px);
    overflow-y: auto;
    max-height: 60vh;

    .img-list {
      .flex;
      flex-wrap: wrap;
      width: calc(100%);
      box-sizing: content-box;
      margin: 20px 0;
      gap: 3%;

      .current-img {
        width: 46px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: unset;
}
</style>